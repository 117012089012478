import React from "react";
import Slider from "react-slick";

import {
    genre1,
    genre2,
    genre3,
    genre5,
    genre6,
    genre7,
    genre8,
    genre9,
  } from '../assets/img';


export default function GenereSlider() {
  var settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
    }] 
  };
  return (
    <Slider {...settings}>
        <div className="item px-4">
            <div className="cat">
                <img src={genre1}/>
                <h6 className="f-24 fw-700">Travel</h6>
                <p>We offer professional book publishing services for travelogues and outdoor travel guides.</p>
            </div>
        </div>
        <div className="item px-4">
            <div className="cat">
                <img src={genre2}/>
                <h6 className="f-24 fw-700">Action & Adventure</h6>
                <p>The most popular type of book publishing keeps readers entertained and excited.</p>
            </div>
        </div>
        <div className="item px-4">
            <div className="cat">
                <img src={genre3}/>
                <h6 className="f-24 fw-700">Comics</h6>
                <p>You can publish thrilling, adventure-serialized stories through comic books.</p>
            </div>
        </div>
        <div className="item px-4">
            <div className="cat">
                <img src={genre5}/>
                <h6 className="f-24 fw-700">Drama</h6>
                <p>We offer professional book publishing for your characters and drama series.</p>
            </div>
        </div>
        <div className="item px-4">
            <div className="cat">
                <img src={genre6}/>
                <h6 className="f-24 fw-700">Health</h6>
                <p>Expert-written health books can help people stay healthy.</p>
            </div>
        </div>
        <div className="item px-4">
            <div className="cat">
                <img src={genre7}/>
                <h6 className="f-24 fw-700">Horror</h6>
                <p>People want thrills and new experiences, so publish your horror stories.</p>
            </div>
        </div>
        <div className="item px-4">
            <div className="cat">
                <img src={genre8}/>
                <h6 className="f-24 fw-700">Romance</h6>
                <p>Are you a romance author? Show your romantic words to the lovers.</p>
            </div>
        </div>
        <div className="item px-4">
            <div className="cat">
                <img src={genre9}/>
                <h6 className="f-24 fw-700">Science Fiction</h6>
                <p>Publish the facts about how actual or imagined science affects people and societies.</p>
            </div>
        </div>
    </Slider>
  );
}