import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "creativeghostwriters.org"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=660861';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://www.amazonkindlewriters.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  return (
    <Modal show={open} onHide={onCloseModal} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="lozad">
          <h3 className="f-24 fw-700 text-center">
            Signup Now To Avail <span className="clr-2">50%</span> Off
          </h3>
          <button type="button" class="btn-close modal-close" onClick={onCloseModal}>
              <span aria-hidden="true">&times;</span>
          </button>
          <form className="form_submission" method="POST" onSubmit={onSubmitFunc}>
            <div className="row justify-content-center">
              <div className="form-group col-md-12">
                <input
                  className="form-control mb-3"
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name || ''}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
                <input
                  className="form-control mb-3"
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email || ''}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
                <input
                  className="form-control mb-3"
                  type="text"
                  name="phone"
                  minLength="10"
                  maxLength="12"
                  placeholder="Your Phone"
                  value={formData.phone || ''}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                />
              </div>
              <div className="form-group col-md-12">
                <textarea
                  className="form-control mb-3"
                  autoComplete="nope"
                  name="message"
                  placeholder="Enter Brief"
                  rows="3"
                  value={formData.message || ''}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                ></textarea>
                {loading ? (
                  <div className="loader">
                    <img alt="loader" src="loader.gif" />
                  </div>
                ) : (
                  <button
                    className="btn w-100"
                    type="submit"
                    name="cta1"
                    value="Submit Now"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
      </Modal.Body>
    </Modal>
  );
};