import React from "react";
import Slider from "react-slick";

import {
    WEBSITE_NAME,
  } from '../config';

import {
    clientThird,
    clientFourth,
    clientFive,
    clientSix,
  } from '../assets/img';


export default function ReviewSlider() {
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
    }] 
  };
  return (
    <Slider {...settings}>
        <div className="item px-3">
            <div className="box review p-3 text-center">
                <div className="image"><img className="mx-auto" src={clientThird}/></div>
                <div className="discription">
                    <strong>Emeryn Alexandria</strong>
                    <p>
                        I had a fantastic experience working with Amazon Kindle Writer's service. They were
                        able to effectively target my book's audience and increase its visibility on various
                        online platforms. I highly recommend
                        their services to any author looking to improve their book's sales and exposure.
                    </p>
                </div>
            </div>
        </div>
        <div className="item px-3">
            <div className="box review p-3 text-center">
                <div className="image"><img className="mx-auto" src={clientFive}/></div>
                <div className="discription">
                    <strong>Phoenix Xavier</strong>
                    <p>
                        Amazon Kindle Writer's book marketing service was exactly what I needed to get my
                        book
                        noticed. They were knowledgeable, and professional, and provided detailed reports on the
                        progress of my book's marketing
                        campaign. I am thrilled with the results and would use their services again.
                    </p>
                </div>
            </div>
        </div>
        <div className="item px-3">
            <div className="box review p-3 text-center">
                <div className="image"><img className="mx-auto" src={clientFourth}/></div>
                <div className="discription">
                    <strong>David Epstein</strong>
                    <p>
                        With the assistance of the {WEBSITE_NAME} they helped me become a
                        published
                        author, I am quite grateful. If you are looking for a competent organization that
                        provides services of the sort, then look no further, {WEBSITE_NAME}'s are
                        your go-to
                        guys. A jaw-dropping and awing experience awaits you! You will get the best service from
                        them. For both me and my wife, it was a proud moment, when we got my first copy of the
                        book from them.
                    </p>
                </div>
            </div>
        </div>
        <div className="item px-3">
            <div className="box review p-3 text-center">
                <div className="image"><img className="mx-auto" src={clientSix}/></div>
                <div className="discription">
                    <strong>Lara Simmons</strong>
                    <p>
                        Working with the {WEBSITE_NAME} was nothing but exciting and successful!
                        These
                        people know how to turn manuscripts into brilliant books. The energy and creativity they
                        brought to my self publish project were amazing. If you want your book to stand out,
                        just hire them! Excitement level: through the roof!
                    </p>
                </div>
            </div>
        </div>
    </Slider>
  );
}