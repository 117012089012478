import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";  
import {
  ADDRESS,
  EMAIL,
  EMAIL_HREF,
  PHONE,
  PHONE_HREF,
  WEBSITE_LOGO,
  WEBSITE_NAME,
} from './config';

import {
  logo,
  clientThird,
  clientFourth,
  clientFive,
  clientSix,
  dmca,
  favIcon,
  footer,
  headerBefore,
  left,
  newYearOffer,
  newYear,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  pImgBottom,
  pImgTop,
  pImg,
  pImg1,
  pay,
  right,
  trustpilot,
  whiteLogo,
  genre1,
  genre2,
  genre3,
  genre5,
  genre6,
  genre7,
  genre8,
  genre9,
  platforms1,
  platforms2,
  platforms3,
  platforms4,
  platforms5,
  platforms6,
  platforms7,
  platforms8,
  platforms9,
  platforms10,
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  servicePublishing,
} from './assets/img';

import PlatformSlider from './platformSlider';
import GenereSlider from './genereSlider';
import ReviewSlider from './reviewSlider';
import { ModalSection } from './layout/modalSection';


function App() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
      phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "authorspublishinglab.com"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=660861';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://www.amazonkindlewriters.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  useEffect(() => {
    const toggleChat = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.toggle();
      }
    };

    // Attach event listeners when the component mounts
    const chatButton = document.querySelector('.chat'); // Replace '.chat' with the appropriate button selector
    if (chatButton) {
      chatButton.addEventListener('click', toggleChat);
    }

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      if (chatButton) {
        chatButton.removeEventListener('click', toggleChat);
      }
    };
  }, []);

  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
      setOpen(true);
  };

  const onCloseModal = () => {
      setOpen(false);
  };
  return (
    <div className="App">
        <header className="py-2 text-white">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-xl-3 col-md-4 col-3 d-md-none">
                        <a href="javascript:;" className="chat d-flex align-items-center"> <span
                                className="fa fa-comment me-2 icon"></span> </a>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-6">
                        <a className="logo" href="javascript:;"><img  src={whiteLogo}/></a>
                    </div>
                    <div className="col-xl-7 col-lg-8 col-md-9 col-3">
                        <div className="row justify-content-end">
                            <div className="col-lg-3 col-md-3 col-6 align-self-center d-none d-md-block">
                                <a href="javascript:;" className="chat d-flex align-items-center">
                                    <span className="me-2 icon chat-icon"></span>
                                    <span className="">
                                        <span className="clr-1 fw-700">
                                            Chat Now
                                        </span>
                                        <br/>
                                    </span>
                                </a>
                            </div>
                            <div className="col-xl-4 col-md-4 col-12">
                                <a href={PHONE_HREF}
                                    className="call d-flex align-items-center justify-content-end justify-content-md-start">
                                    <span className="fa fa-phone me-2 icon"></span>
                                    <span className="d-none d-md-block">
                                        <span className="clr-1 fw-700">Call Now</span><br/>
                                        <span className="fw-700">{PHONE}</span>
                                    </span>
                                </a>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-4 d-none d-md-block"><button className="w-100" type="button"
                                    onClick={onOpenModal}>Get Started</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <section className="banner bg">
            <div className="overlay pt-5">
                <img className="d-lg-block d-none left" src={left}/>
                <div className="container-xl pt-5 mt-3">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-8 col-lg-10 text-center">
                            <p className="subhead text-white">Are you looking for a book publishing company?</p>
                            <h1 className="f-50 text-white">Empowering Authors with Top Book Publishers for Success</h1>
                            <p className="text-white">Entrust our team of professional book publishers from the best book
                                publishing companies to provide a seamless publishing experience specifically designed to
                                propel your book towards success.</p>
                            <div className="row justify-content-center py-2">
                                <div className="col-sm-8 col-10">
                                    <ul
                                        className="list-unstyled text-white c-list row row-cols-md-2 row-cols-1 text-md-start mb-0">
                                        <li className="col mb-2">Publishing by experts</li>
                                        <li className="col mb-2">10,000+ Publishing Platforms.</li>
                                        <li className="col mb-2">Author guidance.</li>
                                        <li className="col mb-2">Quick Turn Around Time.</li>
                                    </ul>
                                </div>
                            </div>
                            <form className="form_submission" method="POST" onSubmit={onSubmitFunc}>
                                <img  src={headerBefore}/>
                                <div className="row justify-content-center">
                                    <div className="form-group col-lg-3 col-md-6 mb-3"><input className="form-control" type="text"
                                            name="name" placeholder="Your Name" required value="" onChange={(e) =>setFormData({ ...formData, name: e.target.value })}/></div>
                                    <div className="form-group col-lg-3 col-md-6 mb-3"><input className="form-control" type="email"
                                            name="email" placeholder="Your Email" value="" required onChange={(e) =>setFormData({ ...formData, email: e.target.value,})}/></div>
                                    <div className="form-group col-lg-3 col-md-6 mb-3"><input className="form-control" type="number"
                                            name="phone" minlength="10" maxlength="12" placeholder="Your Phone" value=""
                                            required onChange={(e) =>setFormData({...formData,phone: e.target.value,})}/></div>
                                    <div className="form-group col-lg-3 col-md-6 mb-3"><input className="form-control"
                                            autocomplete="nope" name="message" placeholder="Enter Brief" value="" onChange={(e) =>setFormData({ ...formData,message: e.target.value,})}/></div>
                                    <div className="form-group col-lg-3 col-md-6">
                                      {loading == true ? (
                                              <div className="loader" style="display: none">
                                                <img alt="loader" src="loader.gif"/>
                                              </div>
                                          ) : (
                                            <button className="btn w-100" cite="Submit"
                                              data-hover="Submit" type="submit" name="cta1" value="Submit Now">Submit</button>
                                          )
                                      }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="platforms">
            <div className="container">
                <div className="slider">
                    <PlatformSlider />
                </div>
            </div>
        </section>

        <section className="what-we-do py-5">
            <div className="container-xl">
                <div className="row text-center justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <h2 className="f-700 f-40">What We Do?</h2>
                        <p>{WEBSITE_NAME} offers comprehensive range of book publishing services to help authors
                            publish
                            their books.Our self book publishing experts have a decade of experience and are committed to
                            meeting all your expectations.</p>
                    </div>
                </div>
                <div className="container-xl">
                    <ul className="nav nav-tabs row row-cols-lg-6 row-cols-md-4 justify-content-center row-cols-2 text-center"
                        id="service" role="tablist">
                        <li className="nav-item mb-4" role="presentation">
                            <button className="nav-link active" id="1-tab" data-bs-toggle="tab" data-bs-target="#1"
                                type="button" role="tab" aria-controls="1" aria-selected="true">
                                <img className=" d-block mx-auto" src={service1}/> Book Publishing
                            </button>
                        </li>
                        <li className="nav-item mb-4" role="presentation">
                            <button className="nav-link" id="2-tab" data-bs-toggle="tab" data-bs-target="#2" type="button"
                                role="tab" aria-controls="2" aria-selected="false">
                                <img className=" d-block mx-auto" src={service2}/> Ghostwriting
                                Service
                            </button>
                        </li>
                        <li className="nav-item mb-4" role="presentation">
                            <button className="nav-link" id="3-tab" data-bs-toggle="tab" data-bs-target="#3" type="button"
                                role="tab" aria-controls="3" aria-selected="false">
                                <img className=" d-block mx-auto" src={service3}/> Book Marketing
                            </button>
                        </li>
                        <li className="nav-item mb-4" role="presentation">
                            <button className="nav-link " id="4-tab" data-bs-toggle="tab" data-bs-target="#4" type="button"
                                role="tab" aria-controls="4" aria-selected="true">
                                <img className=" d-block mx-auto" src={service4}/> E-Book
                            </button>
                        </li>
                        <li className="nav-item mb-4" role="presentation">
                            <button className="nav-link" id="5-tab" data-bs-toggle="tab" data-bs-target="#5" type="button"
                                role="tab" aria-controls="5" aria-selected="false">
                                <img className=" d-block mx-auto" src={service5}/> Book Cover Design
                            </button>
                        </li>
                        <li className="nav-item mb-4" role="presentation">
                            <button className="nav-link" id="6-tab" data-bs-toggle="tab" data-bs-target="#6" type="button"
                                role="tab" aria-controls="6" aria-selected="false">
                                <img className=" d-block mx-auto" src={service6}/> E-Book Editing
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="serviceContent">
                    <div className="tab-pane fade show active" id="1" role="tabpanel" aria-labelledby="1-tab">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="service">
                                    <img className="w-100 " src={servicePublishing}/>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <h3 className="fw-700 f-30">Turn Your Dream Into Reality By Becoming A Published Author.</h3>
                                <p> Our excellence has earned us a reputation as one of the best book publishing services
                                    around. We assure you that your book is of the highest quality. Let us help you achieve
                                    your goal of publishing your own book on Amazon. </p>
                                <ul className="list-unstyled c-list row row-cols-md-2 row-cols-1">
                                    <li className="mb-2">Book Design & Formatting</li>
                                    <li className="mb-2">Printing & Production</li>
                                    <li className="mb-2">Self Publishing</li>
                                    <li className="mb-2">Author Support</li>
                                </ul>
                                <div className="row justify-content-lg-end justify-content-center">
                                    <div className="col-xl-4 col-lg-4 col-md-4 mb-3">
                                        <button className="w-100" type="button" onClick={onOpenModal}>Request Quote</button>
                                    </div>
                                    <div className="col-xl-4 col-md-4 mb-2">
                                        <a href="javascript:;" className="chat d-flex align-items-center">
                                            <span className="fa fa-comment me-2 icon"></span>
                                            <span className="">
                                                <span className="clr-2 fw-700">Chat With Us</span>
                                                <br/>
                                                <span className="fw-700">Let's Talk</span>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="col-xl-4 col-md-4 mb-2 ">
                                        <a href={PHONE_HREF} className="call d-flex align-items-center">
                                            <span className="fa fa-phone me-2 icon"></span>
                                            <span className="">
                                                <span className="clr-2 fw-700">Call Now</span>
                                                <br/>
                                                <span className="fw-700">{PHONE}</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="genere py-5 text-center">
            <div className="container-xl">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <h2 className="f-700 f-40">Offering a Wide range of Genres</h2>
                        <p>
                            Our excellence has earned us a reputation as one of the best book publishing services around. We
                            assure you that your book is of the highest quality. Let us help you achieve your goal of
                            publishing your own book on
                            Amazon.
                        </p>
                    </div>
                </div>
                    <GenereSlider />
            </div>
        </section>

        <section className="publish">
            <div className="container cta">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-5 col-xl-4 col-md-6">
                        <h3 className="f-30">Publish Your Own Book</h3>
                        <p className="mb-0">Don't wait any longer to share your story with the world. We make it easy and
                            affordable.</p>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="row justify-content-lg-end justify-content-center">
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-2">
                                <a href="javascript:;" className="chat d-flex align-items-center">
                                    <span className="fa fa-comment me-2 icon"></span>
                                    <span className="">
                                        <span className="clr-1 fw-700">Chat With Us</span>
                                        <br/> <span className="fw-700">Let's Talk</span>
                                    </span>
                                </a>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-2 ">
                                <a href={PHONE_HREF} className="call d-flex align-items-center">
                                    <span className="fa fa-phone me-2 icon"></span>
                                    <span className="">
                                        <span className="clr-1 fw-700">Call Now</span> <br/>
                                        <span className="fw-700">{PHONE}</span>
                                    </span>
                                </a>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12 ">
                                <button className="w-100" type="button" onClick={onOpenModal}>Request Quote</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="fw-700 f-40">Our Process: From Concept to Perfection</h3>
                    </div>
                </div>
                <div className="process-wrapper">
                    <div className="process-item">
                        <h3>Idea Sharing</h3>
                        <div><img src={p1}/> <img className="p-img" src={pImgTop}/> <img
                                src={pImg}/> <img src={pImg1}/></div>
                    </div>
                    <div className="process-item">
                        <h3>Plot Development</h3>
                        <div><img src={p2}/> <img className="p-img" src={pImgBottom}/> <img
                                src={pImg}/> <img src={pImg1}/></div>
                    </div>
                    <div className="process-item active">
                        <h3>Character Creation</h3>
                        <div><img src={p3}/> <img className="p-img" src={pImgTop}/> <img
                                src={pImg}/> <img src={pImg1}/></div>
                    </div>
                    <div className="process-item">
                        <h3>Draft Creation</h3>
                        <div><img src={p4}/> <img className="p-img" src={pImgBottom}/> <img
                                src={pImg}/> <img src={pImg1}/></div>
                    </div>
                    <div className="process-item">
                        <h3>Author Feedback</h3>
                        <div><img src={p5}/> <img className="p-img" src={pImgTop}/> <img
                                src={pImg}/> <img src={pImg1}/></div>
                    </div>
                    <div className="process-item">
                        <h3>Revisions</h3>
                        <div><img src={p6}/> <img className="p-img" src={pImgBottom}/> <img
                                src={pImg}/> <img src={pImg1}/></div>
                    </div>
                    <div className="process-item">
                        <h3>Final Polishing</h3>
                        <div><img src={p7}/> <img className="p-img" src={pImgTop}/> <img
                                src={pImg}/> <img src={pImg1}/></div>
                    </div>
                    <div className="process-item">
                        <h3>Author Approval</h3>
                        <div><img src={p8}/> <img className="p-img" src={pImgBottom}/> <img
                                src={pImg}/> <img src={pImg1}/></div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5 pricing">
            <div className="container-xl">
                <div className="row justify-content-center">
                    <div className="col-lg-10 text-center">
                        <h3 className="fw-700 f-40">Why Choose Us?</h3>
                        <p>
                            {WEBSITE_NAME} offers comprehensive range of book publishing services to help
                            authors
                            publish their books.Our self book publishing experts have a decade of experience and are
                            committed to meeting all your
                            expectations.
                        </p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="row mb-2">
                            <div className="col-2"><strong className="clr-2 f-70 subhead">01</strong></div>
                            <div className="col-10">
                                <h4 className="fw-700 f-24">100% Ownership</h4>
                                <p>You get all the Ownership We make all the effort. You get all the possessions with our
                                    expert book Publishing help.</p>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2"><strong className="clr-2 f-70 subhead">02</strong></div>
                            <div className="col-10">
                                <h4 className="fw-700 f-24">Affordability</h4>
                                <p>We are perfectionists and firmly believe perfection shouldn’t cost your life savings. Our
                                    prices are affordable, and our expert book Publishing online services are unbeatable.
                                </p>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2"><strong className="clr-2 f-70 subhead">03</strong></div>
                            <div className="col-10">
                                <h4 className="fw-700 f-24">Fast Delivery</h4>
                                <p>Our top notch work not only delivers as per your specifications, we understand that time
                                    is money and meet all our deadlines through expert book Publishing help.</p>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-2"><strong className="clr-2 f-70 subhead">04</strong></div>
                            <div className="col-10">
                                <h4 className="fw-700 f-24">Customer Satisfaction</h4>
                                <p>Customer satisfaction is our most priority, as it’s the most crucial part of any
                                    business. We have proved every time that we never compromise on the quality and
                                    standards of our work.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row text-center">
                            <div className="col-6">
                                <div className="box mb-3">
                                    <p className="fw-700 clr-1 f-40 mb-0 subhead">900+</p>
                                    <p className="mb-0">Books Published</p>
                                </div>
                                <div className="box">
                                    <p className="fw-700 clr-1 f-40 mb-0 subhead">50+</p>
                                    <p className="mb-0">Publishing Experts</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="box mb-3 mt-lg-3">
                                    <p className="fw-700 clr-1 f-40 mb-0 subhead">7+</p>
                                    <p className="mb-0">Year of Experience</p>
                                </div>
                                <div className="box">
                                    <p className="fw-700 clr-1 f-40 mb-0 subhead">100%</p>
                                    <p className="mb-0">Satisfaction Rate</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="publish">
            <div className="container cta">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-5 col-xl-4 col-md-6">
                        <h3 className="f-30">Publish Your Own Book</h3>
                        <p className="mb-0">Don't wait any longer to share your story with the world. We make it easy and
                            affordable.</p>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="row justify-content-lg-end justify-content-center">
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-2">
                                <a href="javascript:;" className="chat d-flex align-items-center">
                                    <span className="fa fa-comment me-2 icon"></span>
                                    <span className="">
                                        <span className="clr-1 fw-700">Chat With Us</span> <br/>
                                        <span className="fw-700">Let's Talk</span>
                                    </span>
                                </a>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-2">
                                <a href={PHONE_HREF} className="call d-flex align-items-center">
                                    <span className="fa fa-phone me-2 icon"></span>
                                    <span className="">
                                        <span className="clr-1 fw-700">Call Now</span> <br/>
                                        <span className="fw-700">{PHONE}</span>
                                    </span>
                                </a>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-12">
                                <button className="w-100" type="button" onClick={onOpenModal}>Request Quote</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5 reviews">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 text-center">
                        <h3 className="fw-700 f-40">What Our Clients Say</h3>
                        <p>We have been in the industry for a long period, allowing us to gather a bunch of customers who
                            praise our work. Don’t just believe it, read it yourself.</p>
                    </div>
                </div>
                <ReviewSlider />
            </div>
        </section>

        <footer className="bg">
            <div className="overlay">
                <img className=" w-100" src={footer}/>
                <div className="container py-5">
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <h3 className="f-30 fw-700 text-white">We Would Love To Hear From You</h3>
                            <form className="form_submission" method="POST" onSubmit={onSubmitFunc}>
                                <div className="row justify-content-center">
                                    <div className="form-group col-md-6">
                                        <input className="form-control mb-3" type="text" name="name" placeholder="Your Name"
                                            required value="" onChange={(e) =>setFormData({ ...formData, name: e.target.value })}/>
                                        <input className="form-control mb-3" type="email" name="email" placeholder="Your Email"
                                            value="" required onChange={(e) =>setFormData({ ...formData, email: e.target.value,})}/>
                                        <input className="form-control mb-3" type="number" name="phone" minlength="10"
                                            maxlength="12" placeholder="Your Phone" value="" required onChange={(e) =>setFormData({...formData,phone: e.target.value,})}/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <textarea className="form-control mb-3" autocomplete="nope" name="message"
                                            placeholder="Enter Brief" rows="3" onChange={(e) =>setFormData({ ...formData,message: e.target.value,})}></textarea>
                                            {loading == true ? (
                                                    <div className="loader" style="display: none">
                                                      <img alt="loader" src="loader.gif"/>
                                                    </div>
                                                ) : (
                                                  <button className="btn w-100" cite="Submit" data-hover="Submit" type="submit"
                                                  name="cta1" value="Submit Now">Submit</button>
                                                )
                                            }
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-xl-5 col-lg-6 text-white">
                            <a href={PHONE_HREF} className="call mb-3 d-flex align-items-center">
                                <span className="fa fa-phone me-2 icon"></span>
                                <span className="">
                                    <span className="clr-1 fw-700">Call Now</span> <br/>
                                    <span className="fw-700">{PHONE}</span>
                                </span>
                            </a>
                            <a href={EMAIL_HREF} className="call mb-3 d-flex align-items-center">
                                <span className="fa fa-envelope me-2 icon"></span>
                                <span className="">
                                    <span className="clr-1 fw-700">Mail Us</span> <br/>
                                    <span className="fw-700">{EMAIL}</span>
                                </span>
                            </a>
                            <a href="javascript:;" className="chat mb-4 d-flex align-items-center">
                                <span className="fa fa-map-marker me-2 icon"></span>
                                <span className="">
                                    <span className="clr-1 fw-700">Find Us</span> <br/>
                                    <span className="fw-700">
                                        {ADDRESS}
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6 text-white">
                            <p className="mb-0">© {WEBSITE_NAME} 
                            2024 All rights reserved.</p>
                        </div>
                        <div className="col-lg-5">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-2">
                                    <a href={dmca} target="_blank"><img 
                                            src={dmca}/></a>
                                </div>
                                <div className="col-3"><img  src={trustpilot}/></div>
                                <div className="col-6"><img  src={pay}/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <ModalSection open={open} onCloseModal={onCloseModal} />
    </div>
  );
}

export default App;
