import React from "react";
import Slider from "react-slick";

import {
    platforms1,
    platforms2,
    platforms3,
    platforms4,
    platforms5,
    platforms6,
    platforms7,
    platforms8,
    platforms9,
    platforms10,
  } from '../assets/img';


export default function PlatformSlider() {
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
    }] 
  };
  return (
    <Slider {...settings}>
        <div className="item px-4">
            <div className="platform"><img src={platforms1}/></div>
        </div>
        <div className="item px-4">
            <div className="platform"><img src={platforms2}/></div>
        </div>
        <div className="item px-4">
            <div className="platform"><img src={platforms3}/></div>
        </div>
        <div className="item px-4">
            <div className="platform"><img src={platforms4}/></div>
        </div>
        <div className="item px-4">
            <div className="platform"><img src={platforms5}/></div>
        </div>
        <div className="item px-4">
            <div className="platform"><img src={platforms6}/></div>
        </div>
        <div className="item px-4">
            <div className="platform"><img src={platforms7}/></div>
        </div>
        <div className="item px-4">
            <div className="platform"><img src={platforms8}/></div>
        </div>
        <div className="item px-4">
            <div className="platform"><img src={platforms9}/></div>
        </div>
        <div className="item px-4">
            <div className="platform"><img src={platforms10}/></div>
        </div>
    </Slider>
  );
}